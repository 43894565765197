// saarthi-landing/src/components/public/verifyEmail/EmailUnverified.js

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const EmailUnverified = () => {
    return (
        <Container fluid className="email-verified-container text-center mt-5">
            <Row className="justify-content-center">
                <Col md={8} className="content-style">
                    <h1 className="mb-4" style={{ color: '#28a745' }}>The email has not been verified!</h1>
                    <p className="sub-text" style={{ fontSize: '18px' }}>
                        Unfortunately, we were not able to verify your email. Could you please try again.
                    </p>
                </Col>
            </Row>
        </Container>
    );
};

export default EmailUnverified;
