// src/components/public/FAQ.js
// Component to show list of frequently asked questions

import React from "react";
import { Accordion, Container } from "react-bootstrap";
import faqs from "./data/faq";

const FAQ = ({ numToShow }) => {
    const displayedFaqs = faqs.slice(0, numToShow);

    return (
        <Container>
            <Accordion defaultActiveKey="0">
                {displayedFaqs.map(faq => (
                    <Accordion.Item eventKey={faq.id.toString()} key={faq.id}>
                        <Accordion.Header>{faq.question}</Accordion.Header>
                        <Accordion.Body>
                            {faq.answer}
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </Container>
    );
};

export default FAQ;