import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import Footer from './Footer.js';
import FAQ from './FAQ.js';
import { Link } from 'react-router-dom';
import '../../custom.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

const Home = () => {
    return (
        <div className="home-page">
            {/* Banner section using Container, Row, and Col */}
            <Container fluid className="py-4">
                <Row className="align-items-center justify-content-center text-left">
                    <Col className="content-style">
                        <h1>Simplify shopping and make time for what truly matters...
                            <span style={{ color: '#f5413a' }}>LIVING!</span>
                        </h1>
                        <p className="sub-text" style={{ color: '#252627' }}>
                            Saarthi transforms grocery shopping into a fun and economical experience,
                            giving you more time to focus on the important stuff.
                        </p>
                        <Button variant="primary"
                            className="custom-button"
                            href={process.env.REACT_APP_NEXT_JS_URL}
                            target="_blank"
                            style={{
                                marginTop: '20px',
                                backgroundColor: '#4B88A2',
                                padding: '12px 24px', // Larger padding
                                fontSize: '18px', // Larger font size
                                boxShadow: '0 4px 8px rgba(0,0,0,0.2)'
                            }}>
                            <i className="bi bi-rocket-takeoff" style={{ marginRight: '8px' }}></i>
                            Let's start saving!
                        </Button>
                    </Col>
                    <Col className="home-banner content-style">
                        <img src="/images/home/living.jpg" alt="Banner" className="img-fluid trapezoid-img-left" />
                    </Col>
                </Row>
            </Container>

            <Container fluid className="my-5 pb-4 stats-box-style">
                <Row className="justify-content-center text-center">
                    <Col xs={12} className="content-style">
                        <h2>Your Benefits, Quantified</h2>
                        <p>
                            Experience the Saarthi Advantage.
                            Join our community of savvy shoppers who are saving time and money every day.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col md={4} className="content-style">
                        <div className="number-style">€ 800</div>
                        <div className="description-style">Average annual savings on groceries</div>
                    </Col>
                    <Col md={4} className="content-style">
                        <div className="number-style">3 hours</div>
                        <div className="description-style">Saved each week on shopping and planning</div>
                    </Col>
                    <Col md={4} className="content-style">
                        <div className="number-style">50%</div>
                        <div className="description-style">Increase in coupon utilization</div>
                    </Col>
                </Row>
            </Container>
            <Container fluid className="my-5">
                <Row>
                    <Col sm={5} md={5} lg={5} xl={5} className="content-style">
                        <img src="/images/home/safety_umbrella.jpg"
                            alt="Safety"
                            className="img-fluid"
                            style={{
                                width: '100%',
                                height: '400px',
                                objectFit: 'cover',
                                objectPosition: 'bottom' /* Crop from the top */
                            }}
                        />
                    </Col>
                    <Col sm={7} md={7} lg={7} xl={7} className="content-style">
                        <div className="d-flex align-items-center my-3">
                            <i className="bi bi-cart4" style={{ fontSize: '2rem', marginRight: '20px', verticalAlign: 'middle' }}></i>
                            <div>
                                <h4>Grocery Tracker</h4>
                                <p>Manage your grocery lists, track prices, and find the best deals at your favorite stores.</p>
                            </div>
                        </div>
                        <div className="d-flex align-items-center my-3">
                            <i className="bi bi-ticket-detailed-fill" style={{ fontSize: '2rem', marginRight: '20px', verticalAlign: 'middle' }}></i>
                            <div>
                                <h4>Coupon Organizer</h4>
                                <p>Keep all your coupons in one place. Easily access and apply them when you shop.</p>
                            </div>
                        </div>
                        <div className="d-flex align-items-center my-3">
                            <i className="bi bi-people-fill" style={{ fontSize: '2rem', marginRight: '20px', verticalAlign: 'middle' }}></i>
                            <div>
                                <h4>Community Sharing</h4>
                                <p>Share offers with the Saarthi community and discover deals from others.</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Row className="align-items-center justify-content-center text-center">
                    <Col md={8} className="content-style bordered-title">
                        <h2>Make more time for the moments that matter.</h2>
                        <h6>Let Saarthi handle the shopping details so you can focus on enjoying life with your loved ones.</h6>
                    </Col>
                </Row>
            </Container>
            <Container fluid className="my-5">
                {/* Row to be divied into 2 columns */}
                <Row>
                    <Col md={6} className="text-center content-style">
                        {/* Add an image here */}
                        <img src="/images/home/testimonial_1.jpg" alt="Descriptive Alt Text" className="img-fluid" />
                    </Col>
                    <Col md={6} className="text-left content-style">
                        <h3 className="mb-4">Testimonials</h3>
                        <h5>What are our users saying?</h5>
                        <blockquote className="blockquote">
                            <p className="mb-4">
                                "When they first asked me to try Saarthi, I was reluctant;
                                but I must say, it has been a game changer.
                                Grocery shopping is now easier because I can keep track of offers and, as a bonus, I save money."
                            </p>
                            <footer className="blockquote-footer text-right">Katja Hoffmann, <cite title="Source Title">Initial customer</cite></footer>
                        </blockquote>
                        <blockquote className="blockquote pt-3">
                            <p className="mb-4">
                                "The coupon organizer is a game-changer. I used to throw these coupons,
                                but now I never forget to use a coupon anymore! I can't believe I was throwing
                                these coupons."
                            </p>
                            <footer className="blockquote-footer text-right">Hannah, <cite title="Source Title">Initial customer</cite></footer>
                        </blockquote>
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Row>
                    <Col xs={4} className="text-left">
                        <h2>Need help?</h2>
                        <p>Here are some answers to common questions.</p>
                        <p>
                            More questions? Please visit our&nbsp;
                            <Link to="/faq">FAQ Page</Link> or just&nbsp;
                            <Link to="/contact">contact us</Link>.
                        </p>
                    </Col>
                    <Col>
                        {/* FAQ section with the number of questions to show */}
                        <FAQ numToShow={5} />
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    );
};

export default Home;
