// Footer.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';  // Ensure icons are imported if not already

const Footer = () => {
    return (
        <footer className="text-white mt-5" style={{ backgroundColor: '#AEC15F' }}>
            <Container fluid>
                <Row className="py-3">
                    <Col xs={12} md={4} className="text-left text-md-left">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src="/logo-passivity.png" alt="Logo" height="50" style={{ marginRight: '10px' }} />
                            <h4 style={{ marginBottom: '0' }}>Saarthi</h4>
                        </div>
                    </Col>
                    <Col xs={12} md={4} className="text-center">
                        <h5>Quick Links</h5>
                        <ul className="list-unstyled">
                            <li><a href="/about_us" className="text-white">About Us</a></li>
                            <li><a href="/faq" className="text-white">FAQ</a></li>
                        </ul>
                    </Col>
                    <Col xs={12} md={4} className="text-right text-md-right d-flex flex-column align-items-md-end">
                        <h5>Contact Us</h5>
                        <p>Email: support@saarthi.life</p>
                    </Col>
                </Row>
                <Row className="py-3">
                    <Col xs={12} md={3} className="text-left">
                        © 2024 Saarthi
                    </Col>
                    <Col xs={12} md={9} className="d-flex justify-content-end">
                        <a href="/tnc" className="text-white" style={{ marginRight: '15px' }}>Terms and Conditions</a>
                        <a href="/privacy" className="text-white">Privacy Policy</a>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
