// src/components/public/PrivacyPolicy.js

import React from 'react';
import { Container, Row } from 'react-bootstrap';
import '../../custom.css';
import Footer from './Footer';

function PrivacyPolicy() {
    return (
        <div className='privacy-policy'>
            <Container fluid>
                <Row className="align-items-center justify-content-center text-left">
                    <h2>Privacy Policy for Saarthi</h2>
                    <p><strong>Last Updated: September 15, 2024</strong></p>

                    <p>This Privacy Policy describes how Saarthi ("we," "us," or "our") collects, uses, and discloses your information when you use our web application ("Service"). It also outlines your privacy rights and how the law protects you.</p>

                    <p>By using the Service, you agree to the collection and use of your information as described in this Privacy Policy.</p>

                    <h4>1. Information We Collect</h4>
                    <p>We may collect the following types of information when you use the Service:</p>
                    <ul>
                        <li><strong>Personal Data</strong>: Your email address, name, phone number, and any other personal details you provide while creating an account.</li>
                        <li><strong>Usage Data</strong>: Automatically collected data, such as your device’s IP address, browser type, and pages visited.</li>
                    </ul>

                    <h4>2. Use of Your Information</h4>
                    <p>We use your data to:</p>
                    <ul>
                        <li>Provide and maintain the Service</li>
                        <li>Manage your account</li>
                        <li>Send notifications about the Service, including updates</li>
                        <li>Analyze how the Service is used to improve its features</li>
                        <li>Manage requests or support inquiries</li>
                    </ul>

                    <h4>3. Cookies and Tracking</h4>
                    <p>We use cookies and similar tracking technologies to track your activity on the Service. You can manage cookie settings through your browser, but some features of the Service may not function properly if cookies are disabled.</p>

                    <h4>4. Data Retention</h4>
                    <p>We retain your personal data for as long as necessary to provide the Service or comply with legal obligations. Usage data is generally kept for a shorter period unless required for security or functionality improvements.</p>

                    <h4>5. Data Sharing</h4>
                    <p>We may share your data:</p>
                    <ul>
                        <li>With service providers for monitoring or analyzing the Service</li>
                        <li>To comply with legal obligations</li>
                        <li>With your consent or to protect our legal rights</li>
                    </ul>

                    <h4>6. Data Security</h4>
                    <p>We strive to use commercially acceptable methods to protect your personal data, but no method of transmission over the internet or electronic storage is 100% secure. We cannot guarantee its absolute security.</p>

                    <h4>7. Your Privacy Rights</h4>
                    <p>Depending on your location, you may have the right to:</p>
                    <ul>
                        <li>Access, update, or delete your personal data</li>
                        <li>Restrict or object to certain types of data processing</li>
                        <li>Withdraw your consent at any time</li>
                    </ul>

                    <h4>8. Children’s Privacy</h4>
                    <p>Our Service is not intended for individuals under the age of 13, and we do not knowingly collect data from children. If you believe a child has provided us with personal information, please contact us.</p>

                    <h4>9. Changes to This Privacy Policy</h4>
                    <p>We may update this Privacy Policy from time to time. Any significant changes will be communicated through the Service, and the updated policy will be effective once posted on this page.</p>

                    <h4>10. Contact Us</h4>
                    <p>If you have any questions about this Privacy Policy, you can contact us at <a href="mailto:support@saarthi.cloud">support@saarthi.life</a>.</p>
                </Row>
            </Container>
            <Footer />
        </div>
    );
}

export default PrivacyPolicy;
