// saarthi-ui\src\components\public\ContactUsForm.js
// This is the form to enable users to contact us

import React from "react";

const ContactUsForm = () => {
    return (
        <div>
            <iframe
                src="https://tally.so/embed/mYZgkB?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
                width="100%"
                height="420px"
                title="contact_us"
            >
                Loading...
            </iframe>
        </div>
    );
};

export default ContactUsForm;