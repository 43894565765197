// src/components/public/data/FAQ.js
// Component to show list of frequently asked questions


const faqs = [
    {
        id: 1,
        question: "Is Saarthi free to use?",
        answer: "Yes, Saarthi offers a free version with essential features to help you start saving immediately."
    },
    {
        id: 2,
        question: "How do I add coupons to Saarthi?",
        answer: "Coupons can be added manually or if someone from the community has shared one, you can simply copy it."
    },
    {
        id: 3,
        question: "Can I share deals with others?",
        answer: "Of course! Our community of users is at the very heart of Saarthi. We take care of each other and help each other."
    },
    {
        id: 4,
        question: "Where is the data stored?",
        answer: "The data is stored in a secured database hosted in Europe. We are working to enable data storage in local countries of our customers as well as enabling encryptions."
    },
    {
        id: 5,
        question: "Will Saarthi add more features in the future?",
        answer: "We're constantly working to improve and expand Saarthi to better serve your needs. Our goal is to save your time and money and enabling you to live a stress-free life!"
    },
    {
        id: 6,
        question: "What do you do with my data?",
        answer: "Nothing. Unlike many other platforms, we don't sell or share your data. The only 'usage' of your data will be to create analytics/charts for you."
    },
    // Add additional 20 FAQs here
];

export default faqs;
