import React, { useEffect } from 'react';
import ContactUsForm from './ContactUsForm';
import Footer from './Footer';
import { Container, Row, Col } from 'react-bootstrap';

function Contact() {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when the component mounts
    }, []);
    return (
        <div>
            <Container>
                <Row>
                    <Col md={6} className="text-center content-style">
                        {/* Add an image here */}
                        <img src="/images/contact_us/letters.jpg"
                            alt="Descriptive Alt Text"
                            className="img-fluid"
                            style={{
                                width: '100%',
                                height: '500px',
                                objectFit: 'cover',
                                objectPosition: 'top' /* Crop from the top */
                            }}
                        />
                    </Col>
                    <Col>
                        <h1>Contact Us</h1>
                        <ContactUsForm />
                    </Col>
                    <Footer />
                </Row>
            </Container>
        </div>
    );
}

export default Contact;
