import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../../custom.css';
import Footer from './Footer';
import ContactUsForm from './ContactUsForm';

function About() {
    return (
        <div className='about-us-page'>
            <Container fluid className="pb-5">
                <Row className="align-items-center justify-content-center text-left">
                    <Col className="home-banner content-style">
                        <img src="/images/about_us/calm_about_us_saarthi.jpg" alt="Calm Nature Mountains" className="img-fluid trapezoid-img-right" />
                    </Col>
                    <Col className="content-style">
                        <h1 style={{ color: '#f5413a' }}>Who are we?</h1>
                        <p className="sub-text" style={{ color: '#252627' }}>
                            We, like you, juggle family and work life, along with ensuring that bills are
                            paid and savings are managed.
                            At Saarthi, we know what it's like to balance the demands of family life and work,
                            while still finding time for the little things. That’s why we created Saarthi:
                            a simple, intuitive tool designed to help you streamline the essential tasks
                            of daily life, starting with grocery and coupon management.
                            We believe household management shouldn’t be a burden but an effortless part
                            of our routine.
                        </p>
                    </Col>
                </Row>
            </Container>
            <Container fluid className="my-5">
                {/* Row to be divied into 2 columns */}
                <Row>
                    <Col md={6} className="text-left content-style">
                        <h3 className="mb-4">Our Mission</h3>
                        <p className="sub-text">Our mission is simple: to simplify your life by streamlining household management.</p>
                    </Col>
                    <Col md={6} className="text-center content-style">
                        {/* Add an image here */}
                        <img src="/images/about_us/happy_friends.jpg" alt="Two happy friends" className="img-fluid trapezoid-img-bottom-left" />
                    </Col>
                </Row>
            </Container>
            <Container fluid className="my-5 py-5">
                <Row>
                    <Col sm={5} md={5} lg={5} xl={5} className="content-style">
                        <img src="/images/about_us/time.jpg"
                            alt="Safety"
                            className="img-fluid trapezoid-img-top-left-bottom-right"
                            style={{
                                width: '100%',
                                height: '500px',
                                objectFit: 'cover',
                                objectPosition: 'bottom' /* Crop from the top */
                            }}
                        />
                    </Col>
                    <Col sm={7} md={7} lg={7} xl={7} className="content-style">
                        <h3 className="mb-4 pb-4">Our values: Our 3C Approach</h3>
                        <div className="d-flex align-items-center my-3">
                            <i className="bi bi-clipboard-data value-icon"></i>
                            <div className="value-content">
                                <h4><span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>C</span>ustomer</h4>
                                <p>
                                    We believe in providing real, tangible value.
                                    Every feature in Saarthi is crafted with the user in mind,
                                    and we continuously evolve based on feedback.
                                    Our goal is to make your household tasks easier
                                    and your life more enjoyable as well as stress-free.
                                </p>
                            </div>
                        </div>
                        <div className="d-flex align-items-center my-3">
                            <i className="bi bi-people value-icon"></i>
                            <div className="value-content">
                                <h4><span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>C</span>ommunity</h4>
                                <p>
                                    Saarthi is more than an app—it’s a community.
                                    Users contribute to the platform by sharing grocery offers and tips,
                                    helping everyone benefit. Together, we’re building a network of
                                    people who care about maximizing value and sharing savings.
                                </p>
                            </div>
                        </div>
                        <div className="d-flex align-items-center my-3">
                            <i className="bi bi-building value-icon"></i>
                            <div className="value-content">
                                <h4><span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>C</span>ompany</h4>
                                <p>
                                    We’re dedicated to sustainability, quality, and ethical business practices.
                                    At Saarthi, we prioritize long-term relationships and delivering consistent
                                    value over short-term gains. We are committed to offering exceptional
                                    customer service and building trust with every user.
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container fluid className="my-5">
                {/* Row to be divied into 2 columns */}
                <Row>
                    <Col md={6} className="text-left content-style">
                        <h3 className="mb-4">Why Saarthi?</h3>
                        <p className="sub-text">
                            We understand that household management isn’t just about ticking boxes;
                            it’s about freeing up time so you can focus on the things that truly matter.
                            With Saarthi, grocery shopping becomes simpler, smarter, and more connected.
                            Our user-friendly platform allows you to organize coupons, track offers,
                            and share deals with your community.
                        </p>
                    </Col>
                    <Col md={6} className="text-center content-style">
                        {/* Add an image here */}
                        <img src="/images/about_us/community.jpg" alt="Two happy friends" className="img-fluid trapezoid-img-bottom-left" />
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Row>
                    <Col xs={4} className="text-left">
                        <h2>Contact Us</h2>
                    </Col>
                    <Col>
                        <p>Have questions or feedback? We're here to listen and help! Contact us anytime to learn more about how together, we can make managing your household effortless.</p>
                        <ContactUsForm />
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    );
}

export default About;
