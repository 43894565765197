// src/components/public/T&C.js

import React from 'react';
import { Container, Row } from 'react-bootstrap';
import '../../custom.css';
import Footer from './Footer';

function TnC() {
    return (
        <div className='t&c-page'>
            <Container fluid>
                <Row className="align-items-center justify-content-center text-left">
                    <h2>Terms and Conditions for Saarthi</h2>
                    <p><strong>Last Updated: September 15, 2024</strong></p>

                    <p>Please read these terms and conditions carefully before using the Saarthi web application ("Service").</p>

                    <h4>1. Introduction</h4>
                    <p>
                        Welcome to Saarthi, a household management application designed to help users manage tasks such as groceries and coupons.
                        The current Service is provided free of charge, but we reserve the right to introduce subscription-based features in the future.
                    </p>
                    <p>
                        By accessing or using the Service, you agree to comply with these Terms and Conditions ("Terms").
                        If you disagree with any part of the Terms, you must not use the Service.
                    </p>

                    <h4>2. User Accounts</h4>
                    <p>
                        When creating an account, you must provide accurate, current, and complete information.
                        You are responsible for maintaining the confidentiality of your account information, including your password.
                        Saarthi is not liable for any unauthorized use of your account.
                    </p>

                    <h4>3. Data Storage and User Responsibility</h4>
                    <p>
                        Saarthi allows users to store and manage personal data such as grocery lists and coupons.
                        While we take reasonable measures to secure data, <strong>you store and manage your data at your own risk</strong>.
                        We are not responsible for any loss, damage, or corruption of data stored in the Service.
                    </p>
                    <h4>4. Future Subscription Services</h4>
                    <p>
                        At present, the Service is provided free of charge. In the future, we may introduce subscription fees for premium features.
                        Should such fees be implemented, you will be notified, and you may choose whether or not to subscribe to these additional services.
                    </p>

                    <h4>5. Changes to the Service</h4>
                    <p>
                        Saarthi reserves the right to modify or discontinue the Service, or parts of it, at any time without prior notice.
                        We will endeavor to provide users with reasonable notice in the event of significant changes.
                    </p>

                    <h4>6. Limitation of Liability</h4>
                    <p>
                        Saarthi is provided on an "as-is" and "as-available" basis. To the maximum extent permitted by law,
                        we disclaim all warranties, whether express or implied, including but not limited to merchantability,
                        fitness for a particular purpose, or non-infringement.
                    </p>
                    <p>
                        We are not liable for any direct, indirect, incidental, or consequential damages resulting from your use of the Service,
                        including but not limited to loss of data, incorrect data or unauthorized access to your account.
                    </p>

                    <h4>7. Governing Law</h4>
                    <p>
                        These Terms are governed by and construed in accordance with the laws of Germany, without regard to its conflict of law provisions.
                    </p>

                    <h4>8. Changes to Terms and Conditions</h4>
                    <p>
                        We may update these Terms from time to time. Any significant changes will be communicated to users,
                        and by continuing to use the Service after such changes, you agree to the revised Terms.
                    </p>

                    <h4>9. Contact Us</h4>
                    <p>If you have any questions or concerns regarding these Terms, please contact us at support@saarthi.life.</p>
                </Row>
            </Container>
            <Footer />
        </div>
    );
}

export default TnC;
